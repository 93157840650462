export default {
   
    //HOMOLOGA 
    // apiURL: 'http://homologa.bi-hgrs.saude.ba.gov.br/api',
    // captchaURL: 'http://homologa.bi-hgrs.saude.ba.gov.br/captcha/api/default',

    //PRODUÇÃO
    apiURL: 'https://bi-hgrs.saude.ba.gov.br/api',
    captchaURL: 'https://bi-hgrs.saude.ba.gov.br/captcha/api/default',

    // apiURL: 'http://localhost:8000/api',
    // captchaURL: 'http://localhost:8000/captcha/api/default',
}